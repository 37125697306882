<div class="container">
  <h3>Formulari de Pagament</h3>

  <div class="row">
      <div class="form-group col">
        <label>Nom del Pagador</label>
        <input class="form-control" (input)="onNameChange($event.target.value)" />
      </div>

      <div class="col">
        <label><b>Nom</b></label>
        <div>
          {{ name | titlecase}}
        </div>
      </div>
  </div>

  <div class="row">
    <div class="form-group col">
      <label>Data del Pagament</label>
      <input type="date" class="form-control" (input)="onDateChange($event.target.value)" />
    </div>

    <div class="col">
      <label><b>Data</b></label>
      <div>
        {{ date | date: 'MMMM d, yyyy' }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="form-group col">
      <label>Quantitat del pagament</label>
      <input class="form-control" (input)="onAmountChange($event.target.value)" />
    </div>

    <div class="col">
      <label><b>Import</b></label>
      <div>
        {{ amount | currency: 'EUR' }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="form-group col">
      <label>Milles</label>
      <input class="form-control" (input)="onMilesChange($event.target.value)" />
    </div>

    <div class="col">
      <label><b>Kilometres</b></label>
      <div>
        {{ miles | convert }}
      </div>
    </div>
  </div>
</div>
